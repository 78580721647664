.library-book-createnfos {
  margin-top: 20px;
}

.library-book-createnfos-title {
  display: block;
  margin-top: 20px;
  padding-bottom: 8px;
  font-weight: 700;
}

.library-book-createform {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  flex-wrap: wrap;
}

.library-book-createlumn {
  flex: 0 0 40%;
  box-sizing: border-box;
  padding: 0 10px 0 0;
}

.library-book-createlumn:last-of-type {
  flex: 0 0 60%;
  padding: 0 0 0 10px;
}

.library-book-create_row {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
}

.library-book-createbmit-button {
  margin-top: 24px;
}

.library-book-createlity-badge {
  padding: 2px 8px;
  font-size: 12px;
  color: #f8f8f8;
  background-color: #666;
  border-radius: 16px;
}

.book-list_status-badge--unknown {
  color: #f8f8f8;
  background-color: #666;
}

.book-list_status-badge--available {
  color: #f8f8f8;
  background-color: #5cb85c;
}

.book-list_status-badge--unavailable {
  color: #f8f8f8;
  background-color: #c9203c;
}
