.document-picker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.document-picker_overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #00000060;
  cursor: pointer;
}

.document-picker_wrapper {
  z-index: 10000;
}

.document-picker_container {
  width: 800px;
  /* height: 400px; */
  padding: 8px;
  border-radius: 3px;
  box-shadow: 0 0 4px 1px #00000060;
  background-color: #f8f8f8;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}

.document-picker_tab {
  margin-right: 16px;
}

.document-picker_content {
  padding: 16px;
  flex-grow: 1;
}
