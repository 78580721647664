.button-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 9px;
  font-size: 16px;
  text-decoration: none;
  color: #f8f8f8;
  background: none;
  background-color: #0275d8;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.button-link--no-icon {
  padding: 12px 9px;
}

.button-link--sm {
  font-size: 14px;
  padding: 4px;
}

.button-link--theme {
  background-color: #f36f24;
}

.button-link--inverse {
  color: #f36f24;
  background-color: #333;
}

.button-link--success {
  background-color: #5cb85c;
}

.button-link--danger {
  background-color: #d9534f;
}

.button-link:active {
  box-shadow: inset 0 0 8px 2px #00000060;
  outline: none;
}

.button-link_icon {
  font-size: 24px;
  width: 24px;
  height: 24px;
  padding: 0 6px 0 0;
}

.button-link--sm .button-link_icon {
  font-size: 18px;
  width: 18px;
  height: 18px;
}

.button-link_label {
  padding: 0 6px;
}

.button-link_icon + .button-link_label {
  padding: 0 6px 0 0;
}
