.folder-list_nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 12px 0 12px 0;
}

.folder-list_search {
  display: flex;
  align-items: flex-end;
  width: 650px;
  height: 60px;
}

.folder-list_search-input {
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin-right: 8px;
  padding: 8px 8px 9px 8px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 3px;
  outline-color: #f36f24;
}

.folder-list_pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.folder-list_count {
  font-size: 14px;
  margin-right: 16px;
}

.folder-list_items-per-page {
  display: block;
  width: 80px;
  box-sizing: border-box;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 3px;
  outline-color: #f36f24;
}

.folder-list_table {
  width: 100%;
  font-size: 14px;
}

.folder-list_header {
  display: flex;
  justify-content: stretch;
  align-items: center;
  border-bottom: 2px solid #333;
}

.folder-list_header-cell {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 3px 5px;
  font-weight: bold;
  text-transform: uppercase;
}

.folder-list_header-cell--sortable {
  cursor: pointer;
}

.folder-list_sort-icon {
  font-size: 18px;
  margin-left: 2px;
}

.folder-list_row {
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  color: inherit;
  text-decoration: none;
  border-top: 1px solid #ccc;
}

.folder-list_row:last-of-type {
  border-bottom: 1px solid #ccc;
}

.folder-list_row:first-of-type {
  border-top: none;
}

.folder-list_row:nth-child(odd) {
  background-color: #00000006;
}

.folder-list_row:hover {
  background-color: #00000012;
  border-top: 1px solid #888;
}

.folder-list_row:hover+.folder-list_row {
  border-top: 1px solid #888;
}

.folder-list_row:last-of-type:hover {
  border-top: 1px solid #888;
  border-bottom: 1px solid #888;
}

.folder-list_row:first-of-type:hover {
  border-top: none;
}

.folder-list_cell {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 5px;
  color: inherit;
  text-decoration: none;
  min-height: 27px;
}

.folder-list_cell--id {
  flex: 0 0 48px;
  font-weight: bold;
}

.folder-list_cell--date {
  flex: 0 0 170px;
}

.folder-list_cell--title {
  flex: 1 1 auto;
}

.folder-list_cell--views {
  flex: 0 0 48px;
}

.folder-list_cell--newsletter {
  flex: 0 0 100px;
  justify-content: center;
}

.folder-list_cell--status {
  flex: 0 0 100px;
  justify-content: flex-end;
}

.folder-list_sub_cell {
  width: 50%;
  display: flex;
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: inherit;
  text-decoration: none;
}

.folder-list_status-badge {
  padding: 2px 8px;
  font-size: 12px;
  color: #f8f8f8;
  background-color: #0275d8;
  border-radius: 16px;
}

.folder-list_status-badge--draft {
  color: #f8f8f8;
  background-color: #f36f24;
}

.folder-list_status-badge--published {
  color: #f8f8f8;
  background-color: #5cb85c;
}

.folder-list_newsletter-toggle {
  display: block;
  width: 48px;
  height: 24px;
  overflow: hidden;
  cursor: pointer;
}

.folder-list_newsletter-toggle--loading {
  opacity: .54;
}

.folder-list_newsletter-toggle-icon {
  position: relative;
  top: -12px;
  left: 4px;
  font-size: 48px;
  color: #888;
}

.folder-list_newsletter-toggle--active .folder-list_newsletter-toggle-icon {
  color: #5cb85c;
}