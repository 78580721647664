.layout-nav {
  width: 300px;
  min-height: 100%;
  min-height: calc(100% - 20px);
  color: #f8f8f8;
  background-color: #333;
  padding-bottom: 20px;
}

.layout-nav_section {
  display: flex;
  flex-direction: column;
  padding: 24px 0 0 0;
}

.layout-nav_section-title {
  padding: 10px 0 10px 24px;
  font-size: 18px;
  color: #f8f8f899;
  text-transform: uppercase;
  letter-spacing: 0.25em;
}

.layout-nav_link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 24px 12px 24px;
  font-size: 20px;
  color: #f8f8f8bb;
  text-decoration: none;
}

.layout-nav_link:hover {
  color: #f8f8f8ff;
}

.layout-nav_link--active {
  padding-left: 18px;
  color: #f8f8f8ff;
  border-left: 6px solid #f36f24;
  /* background: linear-gradient(to right, #ffffff20 0%, #ffffff00 66%); */
}

.layout-nav_link-icon {
  margin: 0 14px 0 0;
  font-size: 24px;
  width: 24px;
  height: 24px;
}

.layout-nav_link-label {
  flex-grow: 1;
  position: relative;
}

.layout-nav_link-badge {
  padding: 3px 5px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: medium;
  color: #f8f8f8;
  background-color: #f36f24;
}