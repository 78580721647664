.checkbox {
  display: block;
  width: 100%;
  margin: 20px 0 3px 0;
  user-select: none;
}

.checkbox_label {
  display: inline-block;
  padding-bottom: 4px;
  font-weight: 700;
}

.checkbox_label a {
  color: inherit;
}

.checkbox_tips {
  display: block;
  padding-bottom: 4px;
  font-size: 12px;
  font-style: italic;
}

.checkbox_error {
  display: block;
  padding-bottom: 4px;
  font-size: 12px;
  font-weight: bold;
  color: #d9534f;
}

.checkbox_input {
  display: none;
}

.checkbox_toggle {
  display: block;
  width: 48px;
  height: 24px;
  overflow: hidden;
  margin: 7px 0 6px 0;
  cursor: pointer;
}

.checkbox_toggle-icon {
  position: relative;
  top: -12px;
  left: -3px;
  font-size: 48px;
  color: #888;
}

.checkbox_toggle--active .checkbox_toggle-icon {
  color: #5cb85c;
}
