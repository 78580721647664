.page_actions {
  display: flex;
  margin: 0 0 12px 0;
}

.page_action-button {
  margin-right: 12px;
}

.page_action-button-icon {
  font-size: 24px;
  width: 24px;
  height: 24px;
}
