.loader {
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  border: 4px solid #00000020;
  border-top: 4px solid #f36f24;
  border-radius: 100%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}