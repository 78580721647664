.title-editor {
  margin: 20px 0 0 0;
}

.title-editor_label {
  display: block;
  padding-bottom: 8px;
  font-weight: 700;
}

.title-editor_error {
  display: block;
  padding-bottom: 4px;
  font-size: 12px;
  font-weight: bold;
  color: #d9534f;
}
