* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #333;
  background-color: #f8f8f8;
}
