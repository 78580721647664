.mail-campaign-form_form {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  flex-wrap: wrap;
}

.mail-campaign-form_column {
  flex: 0 0 60%;
  padding: 0;
}

.mail-campaign-form_row {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
}

.mail-campaign-form_submit-button {
  margin-top: 24px;
}

.mail-campaign-form_content {
  width: 100%;
}

.mail-campaign-form_test-mail {
  border: solid 3px #e1e1e1;
  padding: 20px;
  margin-top: 20px;
  padding-top: 0;
  border-radius: 5px;
  margin-bottom: 15px;
}

.mail-campaign-form_test-zone-button {
  margin-top: 20px;
  margin-bottom: 15px;
}

.mail-campaign-form_test-mail-success-message p {
  color: #46bd46;
  padding: 0;
  margin: 0;
  display: inline-block;
  padding: 5px;
}