.link-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 9px;
  font-size: 16px;
  text-decoration: none;
  color: #f8f8f8;
  background-color: #0275d8;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.link-button--no-icon {
  padding: 12px 9px;
}

.link-button--sm {
  font-size: 15px;
  padding: 4px;
}

.link-button--theme {
  background-color: #f36f24;
}

.link-button--inverse {
  color: #f36f24;
  background-color: #333;
}

.link-button--success {
  background-color: #5cb85c;
}

.link-button--danger {
  background-color: #d9534f;
}

.link-button:active {
  box-shadow: inset 0 0 8px 2px #00000060;
}

.link-button_icon {
  font-size: 24px;
  width: 24px;
  height: 24px;
  padding: 0 6px 0 0;
}

.link-button--sm .link-button_icon {
  font-size: 18px;
  width: 18px;
  height: 18px;
}

.link-button_label {
  padding: 0 6px;
}

.link-button_icon + .link-button_label {
  padding: 0 6px 0 0;
}
