.textarea {
  display: block;
  width: 100%;
  margin: 20px 0 0 0;
  user-select: none;
}

.textarea_label {
  display: block;
  padding-bottom: 4px;
  font-weight: 700;
}

.textarea_tips {
  display: block;
  padding-bottom: 4px;
  font-size: 12px;
  font-style: italic;
}

.textarea_error {
  display: block;
  padding-bottom: 4px;
  font-size: 12px;
  font-weight: bold;
  color: #d9534f;
}

.textarea_textarea {
  display: block;
  width: 100%;
  min-height: 120px;
  box-sizing: border-box;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 3px;
  outline-color: #f36f24;
}

.textarea_textarea--error {
  border: 1px solid #d9534f;
}
