.library-book-form_infos {
  margin-top: 20px;
}

.library-book-form_infos-title {
  display: block;
  margin-top: 20px;
  padding-bottom: 8px;
  font-weight: 700;
}

.library-book-form_form {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  flex-wrap: wrap;
}

.library-book-form_column {
  flex: 0 0 40%;
  box-sizing: border-box;
  padding: 0 10px 0 0;
}

.library-book-form_column:last-of-type {
  flex: 0 0 60%;
  padding: 0 0 0 10px;
}

.library-book-form_row {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
}

.library-book-form_submit-button {
  margin-top: 24px;
}

.library-book-form_googleContentAvailability-badge {
  padding: 2px 8px;
  font-size: 12px;
  color: #f8f8f8;
  background-color: #666;
  border-radius: 16px;
}

.library-book-form_googleContentAvailability-badge--unknown {
  color: #f8f8f8;
  background-color: #666;
}

.library-book-form_googleContentAvailability-badge--available {
  color: #f8f8f8;
  background-color: #5cb85c;
}

.library-book-form_googleContentAvailability-badge--unavailable {
  color: #f8f8f8;
  background-color: #c9203c;
}

.library-book-form_infos-value ul {
  margin-left: 20px;
}