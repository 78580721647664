.paginator {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 0 16px 0;
  text-align: center;
}

.paginator_button {
  display: inline-flex;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #333;
  border: 1px solid #333;
  border-right: none;
  cursor: pointer;
}

.paginator_button:last-of-type {
  border-right: 1px solid #333;
}

.paginator_button:focus {
  outline: none;
}

.paginator_button--disabled {
  opacity: .54;
}

.paginator_icon {
  position: relative;
  top: 1px;
  color: inherit;
}

.paginator_label {
  margin: 0 8px;
}
