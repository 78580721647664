.login-form {
  width: 384px;
  padding: 32px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-sizing: border-box;
}

.login-form_error {
  margin: 0 0 16px 0;
  color: #f44336;
}
