.comment-form_form {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  flex-wrap: wrap;
}

.comment-form_column {
  flex: 0 0 60%;
  padding: 0;
}

.comment-form_row {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
}

.comment-form_submit-button {
  margin-top: 24px;
}
