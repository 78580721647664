.article-form_form {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  flex-wrap: wrap;
}

.article-form_column {
  flex: 0 0 50%;
  box-sizing: border-box;
  padding: 0 20px 0 0;
}

.article-form_row {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
}

.article-form_texts {
  flex: 0 0 80%;
}

.article-form_submit-button {
  margin-top: 24px;
}

.article-form_success-message {
  position: relative;
  height: 0px;
}

.article-form_success-message_text {
  color: #5cb85c;
  font-weight: bold;
  position: relative;
  top: -42px;
  left: 270px;
  font-size: 18px;
}

.article-load-from-ean-button {
  margin-top: 8px;
}

@media screen and (max-width: 768px) {
  .article-form_column {
    flex: 0 0 100%;
    padding: 0;
  }

  .article-form_texts {
    flex: 0 0 100%;
  }
}
