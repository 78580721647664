.category-input {
  display: block;
  width: 100%;
  margin: 20px 0 0 0;
  user-select: none;
}

.category-input_label {
  display: block;
  padding-bottom: 4px;
  font-weight: 700;
}

.category-input_tips {
  display: block;
  padding-bottom: 4px;
  font-size: 12px;
  font-style: italic;
}

.category-input_error {
  display: block;
  padding-bottom: 4px;
  font-size: 12px;
  font-weight: bold;
  color: #d9534f;
}

.category-input_input {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 3px;
  outline-color: #f36f24;
}

.category-input_input--error {
  border: 1px solid #d9534f;
}
