.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 9px;
  font-size: 16px;
  text-decoration: none;
  color: #f8f8f8;
  background: none;
  background-color: #0275d8;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.button--no-icon {
  padding: 12px 9px;
}

.button--sm {
  font-size: 14px;
  padding: 4px;
}

.button--theme {
  background-color: #f36f24;
}

.button--inverse {
  color: #f36f24;
  background-color: #333;
}

.button--success {
  background-color: #5cb85c;
}

.button--danger {
  background-color: #d9534f;
}

.button--secondary {
  background-color: #828282;
}

.button:active {
  box-shadow: inset 0 0 8px 2px #00000060;
  outline: none;
}

.button_icon {
  font-size: 24px;
  width: 24px;
  height: 24px;
  padding: 0 6px 0 0;
}

.button--sm .button_icon {
  font-size: 18px;
  width: 18px;
  height: 18px;
}

.button_label {
  padding: 0 6px;
}

.button_icon + .button_label {
  padding: 0 6px 0 0;
}
