.category-list_nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 12px 0 12px 0;
}

.category-list_search {
  display: flex;
  align-items: flex-end;
  width: 650px;
}

.category-list_search-input {
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin-right: 8px;
  padding: 8px 8px 9px 8px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 3px;
  outline-color: #f36f24;
}

.category-list_pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.category-list_count {
  font-size: 14px;
  margin-right: 16px;
}

.category-list_items-per-page {
  display: block;
  width: 80px;
  box-sizing: border-box;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 3px;
  outline-color: #f36f24;
}

.category-list_table {
  width: 100%;
  font-size: 14px;
}

.category-list_header {
  display: flex;
  justify-content: stretch;
  align-items: center;
  border-bottom: 2px solid #333;
}

.category-list_header-cell {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 3px 5px;
  font-weight: bold;
  text-transform: uppercase;
}

.category-list_header-cell--sortable {
  cursor: pointer;
}

.category-list_sort-icon {
  font-size: 18px;
  margin-left: 2px;
}

.category-list_row {
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  color: inherit;
  text-decoration: none;
  border-top: 1px solid #ccc;
}

.category-list_row:last-of-type {
  border-bottom: 1px solid #ccc;
}

.category-list_row:first-of-type {
  border-top: none;
}

.category-list_row:nth-child(odd) {
  background-color: #00000006;
}

.category-list_row:hover {
  background-color: #00000012;
  border-top: 1px solid #888;
}

.category-list_row:hover+.category-list_row {
  border-top: 1px solid #888;
}

.category-list_row:last-of-type:hover {
  border-top: 1px solid #888;
  border-bottom: 1px solid #888;
}

.category-list_row:first-of-type:hover {
  border-top: none;
}

.category-list_cell {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 5px;
  color: inherit;
  text-decoration: none;
  min-height: 27px;
}

.category-list_cell--id {
  flex: 0 0 48px;
  font-weight: bold;
}

.category-list_cell--date {
  flex: 0 0 170px;
}

.category-list_cell--menu-display {
  flex: 0 0 180px;
}

.category-list_cell--priority {
  flex: 0 0 100px;
}

.category-list_cell--view {
  flex: 0 0 100px;
  justify-content: center;
}

.category-list_sub_cell {
  width: 50%;
  display: flex;
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: inherit;
  text-decoration: none;
}

.category-list_status-badge {
  padding: 2px 8px;
  font-size: 12px;
  color: #f8f8f8;
  background-color: #666;
  border-radius: 16px;
}

.category-list_status-badge--top {
  color: #f8f8f8;
  background-color: #5cb85c;
}

.category-list_status-badge--side {
  color: #f8f8f8;
  background-color: #0275d8;
}

.category-list_status-badge--none-no-sub {
  color: #f8f8f8;
  background-color: #d9534f;
}
