.image-input {
  display: block;
  width: 100%;
  margin: 20px 0 0 0;
  user-select: none;
}

.image-input_label {
  display: block;
  padding-bottom: 4px;
  font-weight: 700;
}

.image-input_tips {
  display: block;
  padding-bottom: 4px;
  font-size: 12px;
  font-style: italic;
}

.image-input_error {
  display: block;
  padding-bottom: 4px;
  font-size: 12px;
  font-weight: bold;
  color: #d9534f;
}

.image-input_input {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.image-input_preview {
  display: block;
  /* max-width: 160px;*/
  max-height: 300px;
  max-width: 100%;
  cursor: pointer;
  margin-bottom: 8px;
}
