.layout {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
}

.layout_header {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 42px;
  padding: 0 24px 0 6px;
  box-sizing: border-box;
  color: #f8f8f8;
  background-color: #f36f24;
}

.layout_nav-button {
  display: block;
  width: 38px;
  height: 38px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.layout_nav-icon {
  font-size: 38px;
  color: #333;
}

.layout_logo {
  font-size: 20px;
  font-weight: bold;
  color: inherit;
  text-decoration: none;
}

.layout_user {
  display: flex;
  align-items: center;
}

.layout_user-text {
  margin: 0 16px 0 0;
}

.layout_logout-button {
  position: relative;
  top: 1px;
  color: inherit;
  border: none;
  background: none;
  cursor: pointer;
}

.layout_body {
  flex: 1 1 auto;
  min-height: 0;
  display: flex;
  align-items: stretch;
  position: relative;
}

.layout_sidebar {
  flex-grow: 0;
  flex-shrink: 0;
  width: 0;
  overflow: hidden;
  transition: width .3s ease;
}

.layout_sidebar--active {
  width: 300px;
  overflow-y: auto;
}

.layout_content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: scroll;
}

/* padding-bottom with overflow-y not working in firefox */
.layout_content {
  padding: 20px 20px 0 20px;
}

.layout_content:after {
  content: '';
  display: block;
  height: 20px;
}

@media screen and (max-width: 768px) {
  .layout_user-text {
    display: none;
  }
}
