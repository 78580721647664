.base-export-form_form {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  flex-wrap: wrap;
}

.base-export-form_column {
  flex: 0 0 60%;
  padding: 0;
}

.base-export-form_row {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
}

.base-export-form_buttons-container {
  margin-top: 24px;
  display: flex;
}

.base-export-form_submit-button {
}

.base-export-form_download-button {
  margin-left: 8px;
}
