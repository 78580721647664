.image-list {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.image-list_images {
  flex: 0 0 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.image-list--multi .image-list_images {
  flex: 0 0 75%;
}

.image-list_image {
  flex: 0 0 20%;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
}

.image-list_image-image {
  max-width: 100%;
  max-height: 180px;
}

.image-list_selection-image {
  max-width: 100%;
}
