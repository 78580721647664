.page-header {
  display: flex;
  align-items: center;
  margin: 0 0 12px 0;
  padding: 0 0 4px 0;
  border-bottom: 3px solid #333;
}

.page-header_title {
  font-size: 24px;
  font-weight: bold;
}

.page-header_description {
  margin: 0 0 0 10px;
  font-size: 14px;
  font-style: italic;
  color: #333333cc;
}

.page-header_description-text {
  position: relative;
  top: 3px;
}
