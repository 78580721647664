.article-list_nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin: 12px 0 12px 0;
}

.article-list_search {
  display: flex;
  align-items: flex-end;
  width: 650px;
}

.article-list_search-input {
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin-right: 8px;
  padding: 8px 8px 9px 8px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 3px;
  outline-color: #f36f24;
}

.article-list_pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.article-list_count {
  font-size: 14px;
  margin-right: 16px;
}

.article-list_items-per-page {
  display: block;
  width: 80px;
  box-sizing: border-box;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 3px;
  outline-color: #f36f24;
}

.article-list_content {
  width: 100%;
  overflow-x: scroll;
}

.article-list_table {
  width: 100%;
  min-width: 1380px;
  font-size: 14px;
}

.article-list_header {
  display: flex;
  justify-content: stretch;
  align-items: center;
  border-bottom: 2px solid #333;
}

.article-list_header-cell {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 3px 5px;
  font-weight: bold;
  text-transform: uppercase;
}

.article-list_header-cell--sortable {
  cursor: pointer;
}

.article-list_sort-icon {
  font-size: 18px;
  margin-left: 2px;
}

.article-list_row {
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  color: inherit;
  text-decoration: none;
  border-top: 1px solid #ccc;
}

.article-list_row:last-of-type {
  border-bottom: 1px solid #ccc;
}

.article-list_row:first-of-type {
  border-top: none;
}

.article-list_row:nth-child(odd) {
  background-color: #00000006;
}

.article-list_row:hover {
  background-color: #00000012;
  border-top: 1px solid #888;
}

.article-list_row:hover + .article-list_row {
  border-top: 1px solid #888;
}

.article-list_row:last-of-type:hover {
  border-top: 1px solid #888;
  border-bottom: 1px solid #888;
}

.article-list_row:first-of-type:hover {
  border-top: none;
}

.article-list_cell {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 5px;
  color: inherit;
  text-decoration: none;
}

.article-list_cell--id {
  flex: 0 0 48px;
  font-weight: bold;
}

.article-list_cell--date {
  flex: 0 0 170px;
  justify-content: center;
}

.article-list_cell--title {
  /* display: block; */
}

.article-list_cell--category {
  flex: 0 0 150px;
}

.article-list_cell--sub-category {
  flex: 0 0 200px;
}

.article-list_cell--views {
  flex: 0 0 70px;
  justify-content: center;
}

.article-list_cell--newsletter {
  flex: 0 0 100px;
  justify-content: center;
}

.article-list_cell--positioning {
  flex: 0 0 134px;
  justify-content: flex-start;
}

.article-list_cell--status {
  flex: 0 0 100px;
  justify-content: flex-end;
}

.article-list_cell--livetwwet {
  flex: 0 0 100px;
  justify-content: center;
}

.article-list_category-badge {
  padding: 2px 8px;
  font-size: 12px;
  color: #f8f8f8;
  background-color: #0275d8;
  border-radius: 16px;
}

.article-list_category-badge--null {
  color: #f8f8f8;
  background-color: #d9534f;
}

.article-list_status-badge {
  padding: 2px 8px;
  font-size: 12px;
  color: #f8f8f8;
  background-color: #666;
  border-radius: 16px;
}

.article-list_status-badge--draft {
  color: #f8f8f8;
  background-color: #f36f24;
}

.article-list_status-badge--review {
  color: #f8f8f8;
  background-color: #0275d8;
}

.article-list_status-badge--published {
  color: #f8f8f8;
  background-color: #5cb85c;
}

.article-list_newsletter-toggle {
  display: block;
  width: 48px;
  height: 24px;
  overflow: hidden;
  cursor: pointer;
}

.article-list_newsletter-toggle--loading {
  opacity: .54;
}

.article-list_newsletter-toggle-icon {
  position: relative;
  top: -12px;
  left: 4px;
  font-size: 48px;
  color: #888;
}

.article-list_newsletter-toggle--active .article-list_newsletter-toggle-icon {
  color: #5cb85c;
}

.article-list_positioning-selector {
  padding: 2px 4px;
  border: 1px solid #ddd;
  border-radius: 3px;
  outline: none;
}

.article-list_positioning-selector--loading {
  opacity: .54;
}
